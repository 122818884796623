@charset "utf-8";

@import '../../_inc/variables.scss';
@import '../../_inc/mixins.scss';

.cf {
  *zoom:1
}

.cf:after,
.cf:before {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.fl {
  float: left;
}

.fl-l {
  @include mq() {
    float: left;
  }
}

.fr {
  float: right;
}

.fr-l {
  @include mq() {
    float: right;
  }
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.inner {
  margin: auto;
  max-width: 1200px;
  width: 95%;
}

.contents-box {
  padding: 2vw;
  @include mq() {
    padding: 3vw 4vw;
  }
  a {
    color: #043a77;
    text-decoration: underline;
    word-wrap: break-word;
    &:hover {
      text-decoration: none;
    }
    &.link-b {
      color: $main_text;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.dn {
  display: none !important;
}

.dn-l {
  @include mq() {
    display: none !important;
  }
}

.dn-s {
  @include mq(sp) {
    display: none !important;
  }
}

.dib {
  display: inline-block;
}

.dib-l {
  @include mq() {
    display: inline-block;
  }
}

.db {
  display: block;
}

.db-l {
  @include mq() {
    display: block;
  }
}

.bg-white {
  background-color: #FFF;
}

.bg-gray {
  background-color: #F6F6F6;
}

.bg-blue {
  background-color: #043A77;
}

.require {
  color: #cc0000;
}

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
}

.center {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.w-100-l {
  @include mq() {
    width: 100%;
  }
}

.w-80 {
  width: 80%;
}

.w-80-l {
  @include mq() {
    width: 80%;
  }
}

.w-70 {
  width: 70%;
}

.w-70-l {
  @include mq() {
    width: 70%;
  }
}

.w-60 {
  width: 60%;
}

.w-60-l {
  @include mq() {
    width: 60%;
  }
}

.w-50 {
  width: 50%;
}

.w-50-l {
  @include mq() {
    width: 50%;
  }
}

.w-48 {
  width: 48%;
}

.w-48-l {
  @include mq() {
    width: 48%;
  }
}

.w-45 {
  width: 45%;
}

.w-45-l {
  @include mq() {
    width: 45%;
  }
}

.w-40 {
  width: 40%;
}

.w-40-l {
  @include mq() {
    width: 40%;
  }
}

.w-30 {
  width: 30%;
}

.w-30-l {
  @include mq() {
    width: 30%;
  }
}

.mv1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mv1-l {
  @include mq() {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.mv2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mv2-l {
  @include mq() {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.mv3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mv3-l {
  @include mq() {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mv4-l {
  @include mq() {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.mv5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mv5-l {
  @include mq() {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

.mv6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mv6-l {
  @include mq() {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.pl2 {
  padding-left: 1rem;
}

.pl2-l {
  @include mq() {
    padding-left: 1rem;
  }
}

.pl3 {
  padding-left: 1.5rem;
}

.pl3-l {
  @include mq() {
    padding-left: 1.5rem;
  }
}

.heading-lv1 {
  color: #FFF;
  @include fontCalc(26);
  font-weight: bold;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
  @include mq() {
    @include fontCalc(40);
  }
}

.heading-lv2 {
  color: #043A77;
  @include fontCalc(18);
  font-weight: bold;
  @include mq() {
    @include fontCalc(22);
  }
}

.heading-lv3 {
  position: relative;
  margin-bottom: 15px;
  @include fontCalc(18);
  font-weight: bold;
  @include mq() {
    margin-bottom: 30px;
    @include fontCalc(20);
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: #ccc;
  }
  > span {
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 6em;
      height: 3px;
      background: #043a77;
      z-index: 5;
    }
  }
}

.heading-lv4 {
  margin: 20px 0 10px;
  padding: 0.375rem 0.5rem;
  background: #f1f1f1;
  border-radius: 3px;
  color: #043A77;
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(255, 255, 255, 0.9);
  @include mq() {
    margin: 30px 0 20px;
    @include fontCalc(18);
  }
}

.table {
  width: auto;
  @include fontCalc(14);
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
    @include mq() {
      padding: 10px 15px;
    }
  }
  > thead {
    th {
      background: #f4f3df;
      text-align: center;
    }
  }
  > tbody {
    th {
      background: #f9f9f9;
    }
  }
  &.table-full {
    width: 100%;
  }
  &.table-calendar {
    th, td {
      border: 1px solid #FFF;
    }
    > thead {
      border-bottom: 5px solid #FFF;
      @include mq() {
        border-bottom-width: 10px;
      }
      th {
        background: #626262;
        color: #FFF;
      }
    }
    > tbody {
      tr {
        &:nth-child(odd) {
          th, td {
            background: #f6f4ec;
          }
        }
      }
    }
  }
  &.table-contact {
    th, td {
      background: #f6f6f2;
      border: none;
      border-bottom: 2px solid #fff;
      @include mq(sp) {
        display: block;
        width: 100%;
      }
      @include mq() {
        padding: 1rem 2rem;
        border-bottom: 5px solid #fff;
      }
    }
    th {
      @include mq() {
        max-width: 8em;
      }
    }
    td {
      @include mq() {
        padding-left: 0;
      }
    }
    select,
    input[type="text"],
    textarea {
      padding: 8px;
      border: 1px solid #d9d7d7;
      border-radius: 3px;
      @include mq() {
        border-radius: 5px;
      }
    }
    select {
      padding-right: 30px;
      background: url(../../../common/img/icon-form_select_g.png) no-repeat 96% center #FFF;
      background-size: 12px auto;
    }
    &.-confirm {
      th {
        @include mq() {
          max-width: 100%;
          white-space: nowrap;
        }
      }
    }
  }
}

.table-scroll {
  @include mq(sp) {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    th, td {
      white-space: nowrap;
    }
  }
}

.form-contact {
  .btn-submit,
  .btn-reset {
    display: block;
    padding: 1em 0;
    width: 45%;
    border: 2px solid;
    cursor: pointer;
    line-height: 1;
    font-weight: bold;
  }
  .btn-submit {
    background: #043a77;
    border-color: transparent;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  .btn-reset {
    background: transparent;
    border-color: #043a77;
    color: #043a77;
    &:hover {
      opacity: 0.7;
    }
  }
}

.form-error {
  background-color: #fff2f2;
  border: 1px solid #ff2121;
  padding: 1em 2em;
  color: #020202;
}

.contact-policy {
  margin-top: 2rem;
  padding: 15px;
  background: #f6f6f6;
  > strong {
    color: #043a77;
  }
}

.entry-body {
  > * {
    *zoom: 1;
    &:after,
    &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
}

.lh1 {
  @include mq() {
    line-height: 1;
  }
}

.lh1-l {
  line-height: 1;
}

.b {
  font-weight: bold;
}

.red {
  color: #f00 !important;
}