$main_blue: #54c3f1;
$sub_blue: #bfe6f6;
$deep_blue: #004098;
$main_text: #333333;
$bg_gray: #969696;
$bg_lightgray: #CCC;
$yellow: #FFF100;

$breakpoints: (
  'sp': 'screen and (max-width: 768px)',
  'md': 'screen and (min-width: 769px)',
  'tb': 'screen and (min-width: 1040px)',
) !default;